import axios from "axios";

// const API = axios.create({ baseURL: 'http://localhost:5000'})
const API = axios.create({ baseURL: process.env.REACT_APP_API });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile")).token
    }`;
  }

  return req;
});

// export const fetchInvoices =() => API.get('/invoices')
export const fetchInvoice = (id) => API.get(`/invoices/${id}`);
export const addInvoice = (invoice) => API.post("/invoices", invoice);
export const updateInvoice = (id, updatedInvoice) =>
  API.post(`/invoices/${id}`, updatedInvoice);
export const deleteInvoice = (id) => API.post(`/invoices/delete/${id}`);
export const fetchInvoicesByUser = (searchQuery) =>
  API.get(`/invoices/user?searchQuery=${searchQuery.search}`);
export const fetchInvoices = () => API.get("/invoices");
export const fetchInvoicesByPage = ({
  page,
  rowsPerPage,
  startDate,
  endDate,
}) => API.post("/invoices/page", { page, rowsPerPage, startDate, endDate });

export const fetchMeeting = (id) => API.get(`/meetings/${id}`);
export const addMeeting = (meeting) => API.post("/meetings", meeting);
export const updateMeeting = (id, updatedMeeting) =>
  API.post(`/meetings/${id}`, updatedMeeting);
export const deleteMeeting = (id) => API.post(`/meetings/delete/${id}`);
export const fetchMeetingsByUser = (searchQuery) =>
  API.get(`/meetings?searchQuery=${searchQuery.search}`);
export const fetchMeetings = () => API.get("/meetings");

export const fetchClient = (id) => API.get(`/clients/${id}`);
export const fetchClients = (page) => API.get(`/clients?page=${page}`);
export const addClient = (client) => API.post("/clients", client);
export const updateClient = (id, updatedClient) =>
  API.post(`/clients/${id}`, updatedClient);
export const deleteClient = (id) => API.post(`/clients/delete/${id}`);
export const fetchClientsByUser = (searchQuery) =>
  API.get(`/clients/user?searchQuery=${searchQuery.search}`);
export const fetchAllClients = () => API.get("/clients");




export const fetchSupplier = (id) => API.get(`/suppliers/${id}`);
export const fetchSuppliers = (page) => API.get(`/suppliers?page=${page}`);
export const addSupplier = (client) => API.post("/suppliers", client);
export const updateSupplier = (id, updatedSupplier) =>
  API.post(`/suppliers/${id}`, updatedSupplier);
export const deleteSupplier = (id) => API.post(`/suppliers/delete/${id}`);
export const fetchSuppliersByUser = (searchQuery) =>
  API.get(`/suppliers/user?searchQuery=${searchQuery.search}`);
export const fetchAllSuppliers = () => API.get("/suppliers");

// export const adminSignIn =(formData)=> API.post('/users/adminSignin', formData)
export const adminSignUp = (formData) =>
  API.post("/users/adminSignup", formData);
export const signIn = (formData) => API.post("/users/signin", formData);
export const signUp = (formData) => API.post("/users/signup", formData);
export const forgot = (formData) => API.post("/users/forgot", formData);
export const reset = (formData) => API.post("/users/reset", formData);
export const fetchUsers = () => API.get("/users");
export const deleteUser = (id) => API.post(`/users/delete/${id}`);
export const updateUser = (id, updatedUser) =>
  API.post(`/users/${id}`, updatedUser);

export const fetchProfilesBySearch = (searchQuery) =>
  API.get(
    `/profiles/search?searchQuery=${
      searchQuery.search || searchQuery.year || "none"
    }`
  );
export const fetchProfile = (id) => API.get(`/profiles/${id}`);
export const fetchProfiles = () => API.get("/profiles");
export const fetchProfilesByUser = (searchQuery) =>
  API.get(`/profiles?searchQuery=${searchQuery.search}`);
// console.log("fetchProfilesByUser called in client")
// console.log(searchQuery.search)

export const createProfile = (newProfile) => API.post("/profiles", newProfile);
export const updateProfile = (id, updatedProfile) =>
  API.post(`/profiles/${id}`, updatedProfile);
export const deleteProfile = (id) => API.post(`/profiles/delete/${id}`);

export const fetchItem = (id) => API.get(`/items/${id}`);
export const fetchItems = () => API.get("/items");
export const addItem = (item) => API.post("/items", item);
export const updateItem = (id, updatedItem) =>
  API.post(`/items/${id}`, updatedItem);
export const deleteItem = (id) => API.post(`/items/delete/${id}`);
export const fetchItemsByUser = (searchQuery) =>
  API.get(`/items/user?searchQuery=${searchQuery.search}`);
